import React from 'react'
import { Link } from 'gatsby'
import {
  phone,
  email,
  chat,
  samenwerken,
  heroKlantenservice
} from 'images/hulp/klantenservice'
import CtaRow from 'components/cta-row'
import Layout from '../../layouts'
import Helmet from 'react-helmet'

const KlantenservicePage = ({location}) => (
  <Layout location={location}>
    <main className="klantenservice home animated fadeInPage">

      <Helmet>
        <title>Klantenservice Employes | Neem contact op</title>
        <meta name="description" content="Heb je een vraag over Employes? In onze veelgestelde vragen zetten we de antwoorden voor je op een rij. Of neem contact op per telefoon of email." />
        <meta itemprop="name" content="Klantenservice Employes | Neem contact op" />
        <meta itemprop="description" content="Heb je een vraag over Employes? In onze veelgestelde vragen zetten we de antwoorden voor je op een rij. Of neem contact op per telefoon of email."/>
        <meta itemprop="image" content="/static/meta-img.png" alt="employes klantenservice"/>
      </Helmet>

      <section className="padding-xxl header">
        <div className="container-md relative">
          <div className="grid yg">
            <div className="col-6">
              <div>
                <h1>Klantenservice</h1>
                <p className="streamer large margin-s-bottom">Heb je een vraag over Employes? In onze veelgestelde vragen zetten we de antwoorden voor je op een rij.</p>
                <Link to="/faq/" className="btn secondary lg">Bekijk veelgestelde vragen</Link>
              </div>
            </div>
            <div className="hero-illustration">
              <img src={heroKlantenservice} alt="illustratie klantenservice"/>
            </div>
          </div>
        </div>
      </section>

      <section className="send-an-email padding-xl">
        <div className="container-sm">
          <div className="grid align-middle yg">
            <div className="col-6">
              <div className="contact-card">
                <div className="contact-card-container">
                  <div className="contact-card-title margin-xs-bottom">
                    <img src={phone} alt="icoon telefoon"/>
                    <h5 className="no-margin">Telefoon</h5>
                  </div>
                  <div className="contact-card-content">
                    <p className="margin-xs-bottom">Bel ons maandag tot en met vrijdag tussen 09.00 en 17.00 uur.</p>
                    <p className="streamer">Tel. 020 808 5481</p>
                  </div>
                </div>

                <div className="contact-card-container">
                  <div className="contact-card-title margin-xs-bottom">
                    <img src={email} alt="icoon email"/>
                    <h5 className="no-margin">Email</h5>
                  </div>
                  <div className="contact-card-content">
                    <p className="">
                      Vul het contactformulier in of e-mail ons via: <a href="mailto:support@employes.nl" className="">support@employes.nl</a>
                    </p>
                  </div>
                </div>

                <div className="contact-card-container">
                  <div className="contact-card-title margin-xs-bottom">
                    <img src={chat} alt="icoon chat"/>
                    <h5 className="no-margin">Chat</h5>
                  </div>
                  <div className="contact-card-content">
                    <p className="margin-m-bottom">Krijg meteen antwoord op maandag tot en met vrijdag tussen 09.00 en 17.00 uur.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="title margin-m-bottom">
                <h5 className="eyebrow turq no-margin">Direct een bericht sturen</h5>
                <h2>Vul het contactformulier in</h2>
                <p>Stuur ons een bericht via onderstaand formulier en wij nemen zo snel mogelijk contact met je op via e-mail.</p>
              </div>

              <div className="form">
                <form name="contact" method="POST" action="" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="email" name="email" placeholder="Jouw e-mail" className="margin-s-bottom" />
                  <input type="text" name="text" placeholder="Onderwerp" className="margin-s-bottom" />
                  <textarea type="text" name="message" placeholder="Bericht" className="margin-s-bottom" />
                  <button type="submit" className="btn primary lg flex right">Verzenden</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaRow light />

      <section className="address padding-xl">
        <div className="container-md">
          <div className="grid text-center yg">
            <div className="col-6">
              {/*<img src={samenwerken} className="icon"/>*/}
              <div>
                <h5>Employes B.V.</h5>
                <p>KvK: 64254615</p>
                <p>BTW: NL855587040B01</p>
            </div>
            </div>

            <div className="col-6">
              {/*<img src={samenwerken} className="icon"/>*/}
              <div>
                <h5>Bezoek & postadres</h5>
                <p>Wibautstraat 137D</p>
                <p>1097 DN Amsterdam</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default KlantenservicePage
